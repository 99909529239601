<template>
  <div class="container justify-content-center">

    <NavBar :user="user" />

<div class="container text-center">
  <div class="row">
    <div class="col-2">

      <img class="" src="/logo_coursGPT.png" alt="">
      <h1 class="text-uppercase">Administration</h1>

      <div @click="displ('org')" :class="this.display=='org' ? 'selected':''">Organisations</div>
      <div @click="displ('user')" :class="this.display=='user' ? 'selected':''">Utilisateurs</div>
      <div v-if="user && user.role==1" @click="displ('rncp')" :class="this.display=='rncp' ? 'selected':''">RNCP</div>
      <div v-if="user && user.role==1" @click="displ('cgxs')" :class="this.display=='cgxs' ? 'selected':''">CGU / CGV / Mentions</div>

    </div>
    <div class="col">

      <AdminUsers v-if="display=='user'" :user="user" :org_id="org_id"/>
      <AdminOrganisations v-if="display=='org'" :user="user" :org_id="org_id"/>
      <AdminRncp v-if="display=='rncp'"/>

      <AdminProfils v-if="display=='prof'"/>

      <AdminConfig v-if="display=='cgxs'" @onAlert="onAlert"/>

      <BottomBar/>

    </div>
  </div>
</div>

    <div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'
import AdminConfig from '@/components/AdminConfig.vue'
import AdminProfils from '@/components/AdminProfils.vue'
import AdminUsers from '@/components/AdminUsers.vue'
import AdminOrganisations from '@/components/AdminOrganisations.vue'
import AdminRncp from '@/components/AdminRncp.vue'

export default {
  data(){
      return {
        user: null,
        display: 'org',
        data:{},
        org_id: null,
      }
  },
  name: "AdminView",
  //props: ['user'],
  components: {
    NavBar,BottomBar,
    AdminConfig, AdminUsers, AdminOrganisations, AdminProfils, AdminRncp,
  },
  methods: {
    onAlert(variant, message) {this.$emit('onAlert', variant, message)},
    displ(x) {this.display=x},
  },
  beforeRouteUpdate (to) { // Page change
    this.org_id=to.params.id
    console.log('=>ORG:', this.org_id)
  },
  mounted() {
    console.log('MOUNT AdminView, ', this.$route.params.id);
    this.org_id = this.$route.params.id;
    MyFlashDS.identifyUser().then(res=>{
            this.user=res.data;
            this.user.role=this.user.roles[0].role
            this.onAlert('info', 'Panneau administration prêt');
            });
  },
}
</script>

<style scoped>

div.red {background-color: red;}

h3 {
  margin-top: 5vh;
}

div.selected {
  background-color: #87bfe9;
}
h1 {
  color: var(--couleur-principale);
  font-size: 1vmax;
}

img {
    max-width: 100%;
}

</style>
