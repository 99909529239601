<template>
  <div class="container justify-content-center">

<div class="container text-center">
  <div class="row">
    <div class="col-2">

    Les profils
    <div @click="doShow(idx)" class='field font-weight-bold mt-2' :style="'background: '+field.color" v-for="(field,idx) in profils" :key="idx">
      {{field.name}}
    </div>
    <button type="button" @click="doSave" class="btn btn-outline-primary buttons mt-2">{{$t('save')}}</button>

    </div>
    <div class="col">

    <div v-for="(block,idx) in profils" :key="idx">
      <div v-if="idx==index">
      <h2>{{block.name}} ({{idx}})</h2>
      <div v-for="(field,idf) in block.fields" :key="idf">
        <img v-if="field.logo" :src="field.logo" class="logo"/>{{field.name}} : {{field.type}}<div v-if="field.type=='list'">***</div>
      </div>

      </div>
    </div>

    </div>
  </div>
</div>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        index:'',

        profils: [
          {name:'Présentation', color:'#FFFFFF', fields:[
            {id:'presentation', name:'', type:'text'}
          ]},
          {name:'Identité', color:'#00B5EC', fields:[
            {id:'name', name:'Nom', type:'text'},
            {id:'surname', name:'Prénom', type:'text'},
            {id:'birthname', name:'Nom naissance', type:'text'},
            {id:'birthdate', name:'Date naissance', type:'date'},
            {id:'birthplace', name:'Lieu naissance', type:'text'},
            {id:'size', name:'Taille', type:'text'},
            {id:'weight', name:'Poids', type:'text'},
            {id:'eye', name:'Yeux', type:'text'},
            {id:'hair', name:'Cheveux', type:'text'},
            {id:'nationality', name:'Nationalité', type:'text'},
          ]},
          {name:'Coordonnées', color:'#32B92D', fields:[
            {id:'address', name:'Adresse', type:'text'},
            {id:'CP', name:'CP', type:'text'},
            {id:'town', name:'Ville', type:'text'},
            {id:'country', name:'Pays', type:'text'},
            {id:'tel', name:'Tel fixe', type:'text'},
            {id:'mobile', name:'Mobile', type:'text'},
          ]},
          {name:'Réseaux Sociaux', color:'#8E44AD', fields:[
            {id:'facebook', name:'Facebook', type:'text', logo:'logo_facebook.png'},
            {id:'instagram', name:'Instagram', type:'text', logo:'logo_instagram.png'},
            {id:'twitter', name:'Twitter', type:'text', logo:'logo_twitter.png'},
            {id:'linkedin', name:'Linkedin', type:'text', logo:'logo_linkedin.png'},
            {id:'youtube', name:'Youtube', type:'text', logo:'logo_youtube.png'},
            {id:'skype', name:'Skype', type:'text', logo:'logo_skype.png'},
            {id:'discord', name:'Discord', type:'text', logo:'logo_discord.png'},
            {id:'shapr', name:'Shapr', type:'text', logo:'logo_shapr.png'},
            {id:'twitch', name:'Twitch', type:'text', logo:'logo_twitch.png'},
            {id:'snapchat', name:'Snapchat', type:'text', logo:'logo_snapchat.png'},
            {id:'tiktok', name:'TikTok', type:'text', logo:'logo_tiktok.png'},
          ]},
          {name: "Centres d'intéret", color:'#7B8D8E', fields:[
            {id:'films', name:'Films', type:'text'},
            {id:'books', name:'Livres', type:'text'},
            {id:'series', name:'Séries', type:'text'},
            {id:'music', name:'Musiques', type:'text'},
            {id:'sport', name:'Sports', type:'list', values:['course à pied','natation', 'cyclisme']},
            {id:'travel', name:'Voyages', type:'text'},
            {id:'games', name:'Jeux vidéo', type:'text'},
            {id:'artists', name:'Artistes', type:'text'},
            {id:'authors', name:'Auteurs', type:'text'},
          ]},
          {name: "Santé", color:'#00AAA0', fields:[
            {id:'allergies', name:'Allergies', type:'text'},
            {id:'traitement', name:'Traitement', type:'text'},
            {id:'operations', name:'Opérations', type:'text'},
            {id:'blood', name:'Groupe Sanguin', type:'text'},
            {id:'illness', name:'Maladies', type:'text'},
            {id:'contact', name:"Contact d'urgence", type:'text'},
            {id:'secu', name:'Sécurité Sociale', type:'text'},
          ]},
          {name: "Professionnel", color:'#C82333', fields:[
            {id:'job', name:'Métier', type:'text'},
            {id:'company', name:'Société', type:'text'},
            {id:'lastexp', name:'Dernière expérience professionnelle', type:'text'},
            {id:'lasttrining', name:'Dernière formation effectuée', type:'text'},
            {id:'language', name:'Langues', type:'text'},
          ]},
        ],

      }
  },
  name: "AdminProfils",
  components: {
    //ImageUpdater,
  },
  methods: {
    doShow(idx) {
        this.index=idx;
    },
    doLoadProfil() {
        MyFlashDS.getConfig('profils').then(res => {
          console.log('profils:', res.data.profils);
          if (res.data.profils) this.profils=res.data.profils;
        })
    },
    doSave() {
      MyFlashDS.saveConfig({profils: this.profils}).then(() => {
        this.doLoadProfil(this.profils);
      });
    },
  },
  mounted() {
    this.doLoadProfil();
  },
}
</script>

<style scoped>

div.field {
  //font-weight: bold;
}

img.logo {width: 5vh;}

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>
