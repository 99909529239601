<template>
  <div class="container justify-content-center">
  <div v-if="config">

    <h4>Qu'est-ce que c'est ?</h4>
    <vue-editor v-model="config.whatisit"/>

    <h4>CGU</h4>
    <vue-editor v-model="config.cgu"/>

    <h4>CGV</h4>
    <vue-editor v-model="config.cgv"/>

    <h4>Mentions légales</h4>
    <vue-editor v-model="config.mentions"/>

    <h4>Email inscription</h4>
    <p>Utiliser "{pseudo}", "{email}", "{url}" comme templates</p>
    <vue-editor v-model="config.email_inscription"/>

    <h4>GPT modèle</h4>
    Modèle: <input v-model="config.gpt_model"/>

    <h4>GPT question</h4>
    <p>templates (double accolades) : {techno.name}, {techno.description}, {question}, {answer}</p>
    <textarea v-model="config.gpt_question" rows="5" cols="100"/>

    <h4>GPT answer</h4>
    <p>templates (double accolades) : {techno.name}, {techno.description}, {question}, {answer}</p>
    <textarea v-model="config.gpt_answer" rows="5" cols="100"/>

    <h4>GPT synthèse</h4>
    <p>templates (double accolades) : {}</p>
    <textarea v-model="config.gpt_synthese" rows="5" cols="100"/>

        <div class="row justify-content-center md-form md-outline">
            <button type="button" @click="doSave" class="btn btn-outline-primary buttons">{{$t('save')}}</button>
        </div>
        <br/><br/><br/>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'
import { VueEditor } from "vue2-editor";

export default {
  data(){
      return {
        user: null,
        config: null,
      }
  },
  name: "AdminConfig",
  //props: ['user'],
  components: {
    VueEditor,
    //ImageUpdater,
  },
  methods: {
    doLoadConfig() {
        MyFlashDS.getConfig('').then(res=>{
                this.config=res.data;
                this.$emit('onAlert', 'info', 'Informations de configuration chargées')
                });
    },
    doSave() {
        MyFlashDS.saveConfig(this.config).then(res=>{
            this.$emit('onAlert', 'success', res.data)
        })
    },
  },
  mounted() {
    console.log('MOUNT AdminConfig');
    this.doLoadConfig();
  },
}
</script>

<style scoped>

button {
  /*width: 20vh;*/
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>
