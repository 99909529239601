<template>
  <div class="container justify-content-center">
  <div v-if="c_rows">

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange" @imageChangeBin="imageChangeBin"/>
    </b-modal>

    <dialog ref="dialog">
      <form id="myform" method="dialog">
        name : <input ref="d_id" name="id" v-model="techno.name"/><br/>
        description: <input ref="d_name" name="name" v-model="techno.description"/><br/>
          <button @click="technoSave">OK</button>
      </form>
    </dialog>


    <h4>RNCP</h4>
        <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="c_columns" :rows="c_rows">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'name'">{{props.row.name}}</div>
                <div v-if="props.column.field == 'competences'">
                  <!--{{props.row.competences}}-->
                  <ul><li v-for="(c,idx) in props.row.competences" :key="idx"><b>{{c.id}}</b> {{c.title}}</li></ul>
                </div>

                <div v-if="props.column.field == 'rncp_id'">
                       <select v-model="props.row.rncp_id.id" @change="rncpChange(props.row)"><option/><option v-for="(rncp,idx) in rncps" :key="idx" :value="rncp.id">{{rncp.name}} {{rncp.title}}</option></select>
                </div>


                <div v-if="props.column.field == 'image'">
                       <img @click="clickImage(props.index,'image')" :src="props.row.image0"/>
                </div>
                <div v-if="props.column.field == 'bandeau'">
                       <img @click="clickImage(props.index,'bandeau')" :src="props.row.bandeau0"/>
                </div>
                <div v-if="props.column.field == 'isSubscribed'">
                  <input type="checkbox" v-model="props.row.isSubscribed" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isCircle'">
                  <input type="checkbox" v-model="props.row.isCircle" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'url'">
                  <input type="text" v-model="props.row.url" @change="doUpdRole2(props.row)"/>
                </div>
            </template>
        </vue-good-table>

        <!--
        Ajouter un client:<br/>
        nom: <input type="text" placeholder="Client" size="10" v-model="newcust.name"/>
        <button type="button" @click="doSaveCust" class="btn btn-outline-primary buttons">{{$t('save')}}</button>
        -->


    <h4>Techno</h4>
    <button type="button" @click="technoAdd" class="btn btn-outline-primary buttons">{{$t('technoAdd')}}</button><br/>
    <select name="techno" @change="changeTechno">
        <option v-for="(t,it) in technos" :key="it" :value="t._id">{{t.name}} (Q:{{t.Q}}) [...{{t._id.substr(20)}}]</option>
    </select>

    <div v-for="(u,iu) in syntheses" :key="iu" class="">
        <div v-for="(s,is) in u.syntheses" :key="is" class="">
          <div v-if="s.techno_id == techno_id">{{u.pseudo}} <b>{{s.grade}}</b> {{s.conclusion}}</div>
        </div>
    </div>

    <ul>
      <li v-for="(q,iq) in questions" :key="iq" class="text-start">
        <div v-if="q.prio!=-1">
          <input type="number" v-model="q.prio" @change="prioChange(iq)" style="width:50px"/> : {{q.question}}
          <div :class="'option '+(io==q.answer)" v-for="(o,io) in q.options" :key="io">{{io}} : {{o}}</div>
        </div>
      </li>
    </ul>

    <br/><br/><br/><br/>


  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        user: null,
        rncps:[],
        newcust: {},
        c_columns: [
            {label: this.$i18n.t('name'), field: 'name',},
            {label: this.$i18n.t('competence'), field: 'competences',},
            ],
        c_rows:[],
        idx:null, field:null, newImage: null, newImageBin: null, // changement image

        technos: null, questions: null, syntheses: [],
        techno: {name:'---',description:''}, techno_id: null,
      }
  },
  name: "AdminRncp",
  //props: ['user'],
  components: {
    ImageUpdater,
  },
  methods: {
    technoSave() {
        MyFlashDS.technoSave(this.techno).then(res=>{
            console.log(res.data);
            this.technos.push(res.data);
        })
    },
    technoAdd() {
        delete this.techno.id
        this.$refs.dialog.showModal();
    },
    changeTechno(evt) {
        this.techno_id = evt.target.value;
        console.log(evt.target)
        MyFlashDS.questionFindAll({techno_id: this.techno_id}).then(res=>{
            this.questions=res.data;
        })
        MyFlashDS.userFindByTechno({techno_id: this.techno_id}).then(res=>{
            this.syntheses=res.data;
        })
    },
    prioChange(iq) {
        let q=this.questions[iq]
        MyFlashDS.questionSave({_id: q._id, prio:q.prio})
    },
    rncpChange(row) {
      //console.log(row)
      console.log(row._id, row.rncp_id.id);
      MyFlashDS.organisationSave({id: row._id, rncp_id:row.rncp_id.id})
    },
    doLoad() {
        MyFlashDS.rncpFindAll().then(res=>{
            this.c_rows=res.data;
        })
        MyFlashDS.technoFindAll().then(res=>{
            this.technos=res.data;
        })
    },
    doUpdRole2(prop) {
      var role=(prop.isSubscribed?8:0)+(prop.isCircle?4:0);
      console.log('ROLE', {id:prop._id, role:role})
      MyFlashDS.saveCustomer({_id:prop._id, role:role, url:prop.url}).then(() => {
        this.doLoadUsers();
      });
    },
    doSaveCust() {
      MyFlashDS.createCustomers(this.newcust).then(()=>{
        this.doLoadCustomers();
      })
    },
    clickImage(idx,field){
      this.idx=idx;
      this.field=field;
      this.$refs.imgModal.show()
    },
    imageChange(data){
      console.log('Admin,imageChange:',data);
      this.newImage=data;
    },
    imageChangeBin(data){
      console.log('Admin,imageChangeBin:',data);
      //this.newImageBin=data;
    },
    doChangeImage() {
        //this.c_rows[this.idx].image=this.newImage;
        this.c_rows[this.idx][this.field]=this.newImage;
            MyFlashDS.saveCustomer(this.c_rows[this.idx]).then(res=>{
                console.log('upload', res);
                this.doLoadCustomers();
            })
    },
  },
  mounted() {
    this.doLoad();
  },
}
</script>

<style scoped>

div.option.true {color: green;}
div.option {font-size: 80%; color: gray; margin-left: 20px;}

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>
